function StickerIcon() {
  return (
    <svg
      fill="none"
      height="30"
      viewBox="0 0 40 40"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#fe2c55">
        <path
          clipRule="evenodd"
          d="m22 29.9563c0-1.3631 0-2.4471.0715-3.3217.0108-.1328.0234-.2626.0382-.3898-.6265.1977-1.2916.3043-1.9806.3043-2.5272 0-4.7132-1.4282-5.8738-3.5137-.2686-.4826-.0951-1.0915.3875-1.3601.4826-.2685 1.0915-.095 1.3601.3875.8355 1.5014 2.3803 2.4863 4.1262 2.4863.9017 0 1.7442-.2612 2.4648-.7177.2347-.1487.4566-.3183.6634-.5065.5388-.6953 1.2269-1.2674 2.0188-1.6709.6992-.3563 1.4629-.5094 2.3585-.5825.8746-.0715 1.9586-.0715 3.3217-.0715h.0348 2.0013c.0077-.6325.0077-1.3385.0076-2.1381v-.1952-.1872c.0003-2.3015.0004-3.663-.3066-4.8088-.8322-3.1059-3.2582-5.5318-6.364-6.36401-1.1458-.30702-2.5073-.30689-4.8088-.30668l-.1872.00001h-1.3709c-1.832 0-3.258 0-4.4078.07844-1.1666.0796-2.1216.24328-2.9988.60665-2.2053.91344-3.95735 2.66549-4.87079 4.87079-.36337.8772-.52705 1.8322-.60665 2.9988-.07844 1.1498-.07844 2.5758-.07844 4.4078v1.3709l-.00001.1872c-.00021 2.3015-.00034 3.663.30668 4.8088.83221 3.1058 3.25811 5.5318 6.36401 6.364 1.1458.307 2.5073.3069 4.8088.3066h.1872.1952c1.2489.0001 2.2694.0002 3.1381-.0293l-.0447-2.0001.0447-.0016zm2.2456-12.0024c.8157 0 1.4769-.6613 1.4769-1.477 0-.8156-.6612-1.4769-1.4769-1.4769s-1.4769.6613-1.4769 1.4769c0 .8157.6612 1.477 1.4769 1.477zm-8.3077 0c.8157 0 1.4769-.6613 1.4769-1.477 0-.8156-.6612-1.4769-1.4769-1.4769s-1.4769.6613-1.4769 1.4769c0 .8157.6612 1.477 1.4769 1.477z"
          fillRule="evenodd"
        />
        <path d="m24.847 32.6252c1.4586-.3908 2.7945-1.0692 3.9443-1.9719 2.4203-1.7222 4.0409-4.4951 4.1964-7.6533h-2.076c-1.3701.0001-2.347.0021-3.1142.0648-.772.0631-1.2431.1825-1.6134.3712-.7527.3835-1.3646.9954-1.7481 1.7481-.1887.3703-.3081.8414-.3712 1.6134-.064.7836-.0648 1.7859-.0648 3.2025v.4381 2.3649c.2971-.0471.5771-.1055.847-.1778z" />
      </g>
    </svg>
  );
}

export default StickerIcon;
